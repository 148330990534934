<template>
  <div class="">
    <PreLoader v-if="isLoading" />

    <form
      autocomplete="off"
      method="post"
      @submit.prevent="addOrUpdateContractForm()"
    >
      <div class="my-card">
        <div class="row">
          <div
            v-if="listEmployeeWorkContractsBindOptions.length"
            class="my-card col-md-12"
          >
            <span class="my-card-title"
              >{{ $t("ContractForms.hintCopy") }}:</span
            >
            <ul class="row">
              <li
                v-for="(option, index) in listEmployeeWorkContractsBindOptions"
                :key="index"
                class="col-md-4"
              >
                <span>*</span>
                {{ option.value }} : {{ option.text }}
              </li>
            </ul>
          </div>
          <CustomInput
            :className="'col-md-4'"
            :id="'contractFormNameAr'"
            :value="contractForm.contractFormNameAr"
            v-on:changeValue="contractForm.contractFormNameAr = $event"
            :title="$t('ContractForms.nameAr')"
            :imgName="'contracts.svg'"
          />
          <CustomInput
            :className="'col-md-4'"
            :id="'contractFormNameEn'"
            :value="contractForm.contractFormNameEn"
            v-on:changeValue="contractForm.contractFormNameEn = $event"
            :title="$t('ContractForms.nameEn')"
            :imgName="'contracts.svg'"
          />
          <CustomInput
            :className="'col-md-4'"
            :id="'contractFormNameUnd'"
            :value="contractForm.contractFormNameUnd"
            v-on:changeValue="contractForm.contractFormNameUnd = $event"
            :title="$t('ContractForms.nameUnd')"
            :imgName="'contracts.svg'"
          />

          <TextArea
            :className="'col-md-12'"
            :id="'contractFormDescriptionAr'"
            :value="contractForm.contractFormDescriptionAr"
            v-on:changeValue="contractForm.contractFormDescriptionAr = $event"
            :title="$t('ContractForms.descriptionAr')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-12'"
            :id="'contractFormDescriptionEn'"
            :value="contractForm.contractFormDescriptionEn"
            v-on:changeValue="contractForm.contractFormDescriptionEn = $event"
            :title="$t('ContractForms.descriptionEn')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-12'"
            :id="'contractFormDescriptionUnd'"
            :value="contractForm.contractFormDescriptionUnd"
            v-on:changeValue="contractForm.contractFormDescriptionUnd = $event"
            :title="$t('ContractForms.descriptionUnd')"
            :imgName="'description.svg'"
          />

          <TextArea
            :className="'col-md-12'"
            :id="'contractFormNotes'"
            :value="contractForm.contractFormNotes"
            v-on:changeValue="contractForm.contractFormNotes = $event"
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />
        </div>
      </div>

      <div class="form-footer">
        <button name="submit" type="submit" class="btn btn-submit">
          {{ submitName }}
        </button>
        <router-link :to="{ name: 'ContractForms' }" class="btn btn-cancel">
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
// import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import CustomInput from "@/components/general/CustomInput.vue";
import TextArea from "@/components/general/TextArea.vue";
import createToastMixin from "@/utils/create-toast-mixin";
import { setDataMultiLang } from "@/utils/functions";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    // CustomSelectBox,
    CustomInput,
    TextArea,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,

      listEmployeeWorkContractsBindOptions: [],
    };
  },
  props: ["contractForm", "submitName"],
  methods: {
    setDataMultiLang,
    async getListEmployeeWorkContractsBind() {
      this.isLoading = true;
      this.listEmployeeWorkContractsBindOptions = [];
      let itemsData =
        this.$store.getters.userData.constantLists
          .listEmployeeWorkContractsBind;
      for (let item in itemsData) {
        this.listEmployeeWorkContractsBindOptions.push({
          value: itemsData[item]["itemToken"],
          text: this.setDataMultiLang(
            this.language,
            itemsData[item]["itemNameAr"],
            itemsData[item]["itemNameEn"]
          ),
        });
      }

      this.isLoading = false;
    },
    addOrUpdateContractForm() {
      this.$emit("addOrUpdateContractForm");
    },
  },
  watch: {},
  created() {
    this.getListEmployeeWorkContractsBind();
  },
};
</script>
